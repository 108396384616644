import * as THREE from 'three'
import Sizes from "./Utils/Sizes.js"
import Time from "./Utils/Time.js"
import Camera from "./Utils/Camera.js"
import Renderer from "./Utils/Renderer.js"
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import sources from './sources.js'
import Debug from './Utils/Debug.js'
import Scroll from './Utils/Scroll.js'
import Cursor from './Utils/Cursor.js'


let instance = null

export default class Experience 
{
  constructor(canvas) 
  {
    if(instance) {
      return instance
    }

    instance = this

    // Options
    this.canvas = canvas

    // Global access
    window.experience = this

    this.debug = new Debug()

    this.sizes =  new Sizes()
    this.cursor = new Cursor()
    this.scroll= new Scroll()
    this.time = new Time()
    this.scene = new THREE.Scene()
    this.resources = new Resources(sources)
    this.camera = new Camera()
    this.renderer = new Renderer()
    this.world = new World()

    this.sizes.on('resize', () =>
    {
      this.resize()
    })

    this.time.on('tick', () =>
    {
      this.update()
    })

  }

  resize () 
  {
    this.camera.resize()
    this.renderer.resize()
  }

  update() 
  {
    this.camera.update()
    this.renderer.update()
  }

  destroy()
  {
    // EventListener
    this.sizes.off('resize')
    this.time.off('tick')
    this.scroll.off('scrolling')
    this.scene.off('section')

    // Meshes parts
    this.scene.traverse((child) =>
    {
      if(child.isMesh)
      {
        // Geometries
        child.geometry.dispose()

        // Materials
        for(const key in  child.material)
        {
          const value = child.material[key]
          if(value && typeof value.dispose === 'function') 
          {
            value.dispose()
          }
        }
      }
    })

    // Camera 
    this.camera.controls.dispose()

    // Renderer
    this.renderer.instance.dispose()

    // Debug
    if(this.debug.active)
    {
      this.debug.gui.destroy()
    }

  }

}