import * as THREE from 'three'
import Experience from '../Experience'

export default class Environment 
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    // Setup
    this.setSunLight()
    this.setEnvironmentMap() 
  }

  setSunLight()
  {
    this.sunLight = new THREE.DirectionalLight(0xffffff, 2.5)
    this.sunLight.name = 'sunlight'
    this.sunLight.castShadow = true
    this.sunLight.shadow.camera.far = 15
    this.sunLight.shadow.mapSize.set(512,512)
    this.sunLight.shadow.normalBias = 0.05
    this.sunLight.position.set(3, 3, 3)
    this.scene.add(this.sunLight)
  }

  setEnvironmentMap()
  {
    this.environmentMap = {}
    this.environmentMap.intensity =  0.4
    this.environmentMap.texture = this.resources.items.environmentMapTexture
    this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace
    this.environmentMap.updateMaterials = () => 
    {
      this.scene.traverse((child)=>
      {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        {
          child.material.envMap = this.environmentMap.texture
          child.material.envMapIntensity = this.environmentMap.intensity
          child.material.needsUpdate = true
        }
      })
    }
    // this.scene.background = this.environmentMap.texture
    this.environmentMap.updateMaterials()
  }
}