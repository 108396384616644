import Experience from "../Experience";
import Environment from './Environment';
import Cyril from "./Objects3D/Cyril";
import Planet from "./Objects3D/Planet";
import Torus from './Objects3D/Torus';
import Sphere from './Objects3D/Sphere';
import Triangle from './Objects3D/Triangle';
import TorusKnot from "./Objects3D/TorusKnot";
import Cone from "./Objects3D/Cone";
import Box from "./Objects3D/Box";

export default class World 
{
  constructor() 
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.scroll = this.experience.scroll
    this.sizes = this.experience.sizes
    this.time = this.experience.time

    this.resources.on('ready', () => {
      this.cyril = new Cyril()
      this.sphere = new Sphere()
      this.planet = new Planet()
      this.torus = new Torus()
      this.triangle = new Triangle()
      this.torusKnot = new TorusKnot()
      this.cone = new Cone()
      this.box = new Box()
      this.environment = new Environment()

      // Setup events
      this.scroll.on('scrolling', () =>
      {
        this.scrolling()
      })

      this.time.on('tick', () =>
      {
        this.update()
      })
      
      this.showDisplay()
    })
  }

  scrolling() 
  {
    this.cyril.scrolling()
    this.planet.scrolling()
    this.sphere.scrolling()
  }

  update()
  {
    this.planet.update()
    this.sphere.update()
    this.torus.update()
    this.triangle.update()
    this.torusKnot.update()
    this.cone.update()
    this.box.update()
  }

  destroy()
  {
    this.scroll.off('scrolling')
    this.time.off('tick')
  }

  showDisplay() {
    const header = window.document.querySelector("header")
    const loadingEL = window.document.querySelector('#loading')

    window.isLoading = false
    if(!window.isLoading ) 
    {
      header.classList.add('show')
      loadingEL.classList.remove('show')
      console.log('3D Textures Loaded')
    }
  }

}