import * as THREE from 'three'
import EventEmitter from "./EventEmitter";

export default class Time extends EventEmitter
{
  constructor() 
  {
    super()
    // Properties
    this.start = Date.now()
    this.clock = new THREE.Clock()
    this.current = this.start
    this.elapsed = 0 // in s
    this.delta = 16  // in ms

    // Wait the second Frame to launch first tick method .
    window.requestAnimationFrame(() =>
    {
        this.tick()
    })

  }

  tick()
  {
    window.requestAnimationFrame(() =>
    {
      const elapsedTime = this.clock.getElapsedTime()

      const currentTime = Date.now()
      this.delta = currentTime - this.current
      this.current = currentTime
      this.elapsed = elapsedTime


      // Emit tick event.
      this.trigger('tick')

      // Infinite loop on the tick method.
      this.tick()
    })
  }


}