import * as THREE from "three"
import Experience from "../../Experience"

export default class Torus 
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resource = this.experience.resources
    this.sizes = this.experience.sizes
    this.scroll = this.experience.scroll
    this.time = this.experience.time
    this.sectionIndex = 1
    this.scaleRatio = this.sizes.isMobile ? 0.5 : 1
    this.widthOffset = this.sizes.isMobile ? 0 : 2
    this.position = { 
      x: this.widthOffset, 
      y: - this.scroll.objectDistance * this.sectionIndex,
      z: 0
    };

    // Setup
    this.setGeometry()
    this.setTextures()
    this.setMaterial()
    this.setMesh()
  }

  setGeometry()
  {
    this.geometry = new THREE.TorusGeometry(0.9, 0.5, 16, 32)
  }

  setTextures()
  {
    this.textures = {}
    this.textures.roughness = this.resource.items.sandRoughnessTexture
    this.textures.ao = this.resource.items.sandAoTexture
    this.textures.normal = this.resource.items.sandNormalTexture

  }

  setMaterial()
  {
    this.material = new THREE.MeshStandardMaterial({ 
      color: '#0af',
      metalness: 0.9,
      roughness: 1,
      roughnessMap: this.textures.roughness,
      aoMap: this.textures.ao,
      normalMap: this.textures.normal,
      transparent: true,
      opacity: 1
    })
  }

  setMesh()
  {
    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.name = 'torus'
    this.mesh.position.set(this.position.x, this.position.y, this.position.z) 
    this.mesh.scale.set(1 * this.scaleRatio, 1 * this.scaleRatio, 1 * this.scaleRatio)
    this.scene.add(this.mesh)
  }

  update() 
  {
    this.mesh.rotation.x =  this.time.elapsed * 0.4 
    this.mesh.rotation.y =  this.time.elapsed * 0.4 
  }
}