import Experience from "../Experience";
import EventEmitter from "./EventEmitter";

export default class Scroll extends EventEmitter
{
  constructor()
  {
    super()
    this.experience = new Experience()
    this.sizes =  this.experience.sizes
    this.scrollY = window.scrollY
    this.previewScrollY =  window.scrollY
    this.scrollDirection = "stop"
    this.currentSection = 0
    this.totalOfSections = 6
    this.objectDistance = 4


    this.header = document.querySelector("header");
    
    this.setEvent()
  }

  setEvent()
  {
    window.addEventListener('scroll', () => {

      // Hide navBar
      if (this.scrollY < 70 || this.scrollY > window.scrollY) {
         this.header.style.top = "0";
          
        } else {
          this.header.style.top = "-70px";
      }

      // Set scroll values
      this.previewScrollY = this.scrollY
      this.scrollY = window.scrollY

      // Set scrolling direction
      if(this.scrollY - this.previewScrollY > 0) {
        this.scrollDirection = "down"
      }else if (this.scrollY - this.previewScrollY < 0) {
        this.scrollDirection = "up"
      }
      

      const newSection = Math.round(this.scrollY / this.sizes.height)

      if(newSection != this.currentSection  && newSection < this.totalOfSections )
      {
          this.currentSection = newSection
      } 

      // Emit Event Scroll
      this.trigger('scrolling')
    })
  }

  destroy() 
  {
    window.removeEventListener('scroll')
  }
}