import * as THREE from 'three'
import Experience from "../Experience";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera 
{
  constructor() 
  {
    this.experience = new Experience()
    this.scene =  this.experience.scene
    this.sizes = this.experience.sizes
    this.canvas = this.experience.canvas
    this.scroll = this.experience.scroll
    this.cursor = this.experience.cursor
    this.position = {x:0, y:0, z:6}
    this.fieldOfView = 35
    this.far = 100
    this.near = 0.1


    // Setup
    this.setInstance()
    this.setControls()
  }

  setInstance() 
  {
    // Create a group for the camera 
    this.cameraGroup = new THREE.Group()
    this.scene.add(this.cameraGroup)
  
    // Create and add 360 degree camera to the group
    this.instance = new THREE.PerspectiveCamera(this.fieldOfView, this.sizes.width / this.sizes.height, this.near, this.far)
    this.instance.position.z = this.position.z
    this.cameraGroup.add(this.instance)
  }


  setControls() 
  {
    // Controls of camera
    this.controls = new OrbitControls(this.instance, this.canvas)
    this.controls.enableDamping = true
  }

  resize() 
  {
    this.instance.aspect = this.sizes.width / this.sizes.height
    this.instance.updateProjectionMatrix()
  }

  update()
  {
 
    this.instance.position.y = - this.scroll.scrollY / this.sizes.height * this.scroll.objectDistance
    this.instance.aspect = this.sizes.width / this.sizes.height
    this.instance.updateProjectionMatrix()

    // Animate  the camera group with parallax
    const parallaxX = this.cursor.x
    const parallaxY = this.cursor.y


    if(!this.sizes.isMobile && this.scroll.currentSection !== 0){
      this.cameraGroup.position.x +=  (parallaxX - this.cameraGroup.position.x) * 0.5
      this.cameraGroup.position.y +=  (parallaxY - this.cameraGroup.position.y) * 0.5
    }else {
      this.cameraGroup.position.y = 0
      this.cameraGroup.position.x = 0
    }

  }

} 