import EventEmitter from "./EventEmitter"

export default class Sizes extends EventEmitter 
{
    constructor() {
      super()

      // Properties
      this.width = window.innerWidth
      this.height = window.innerHeight
      this.pixelRatio = Math.min(window.devicePixelRatio, 2)
      this.isMobile = this.width > 768 ? false : true,


      // Resize event
      window.addEventListener('resize', () => {
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)
        this.isMobile = this.width > 768 ? false : true,

        // Emit resize event
        this.trigger('resize')
        
      })
    }

    destroy(){
      window.removeEventListener('resize')
    }


}