export default [
  {
    name: 'environmentMapTexture',
    type: 'cubeTexture',
    path:
    [
        'textures/environmentMap/px.jpg',
        'textures/environmentMap/nx.jpg',
        'textures/environmentMap/py.jpg',
        'textures/environmentMap/ny.jpg',
        'textures/environmentMap/pz.jpg',
        'textures/environmentMap/nz.jpg'
    ]
  },
  {
    name: 'sandRoughnessTexture',
    type: 'texture',
    path: '/textures/sand/roughness-1.png',
  },
  {
    name: 'sandAoTexture',
    type: 'texture',
    path: '/textures/sand/ao-1.png',
  },
  {
    name: 'sandNormalTexture',
    type: 'texture',
    path: '/textures/sand/normal-1.png',
  },
  {
    name: 'cyrilColorTexture',
    type: 'texture',
    path: '/textures/cyril/cyril-diff.png'  
  },
  {
    name: 'planetColorTexture',
    type: 'texture',
    path: '/textures/backgrounds/earth.jpg'  
  },
  {
    name: 'waterMatCapTexture',
    type: 'texture',
    path: '/textures/matcaps/9.png'  
  },
  {
    name: 'spaceColorTexture',
    type: 'texture',
    path: '/textures/backgrounds/background-3.jpg'  
  },
  {
    name: 'space2ColorTexture',
    type: 'texture',
    path: '/textures/backgrounds/space.jpg'  
  }
]