import * as THREE from 'three'
import Experience from '../../Experience'

export default class Sphere 
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resource = this.experience.resources
    this.sizes = this.experience.sizes
    this.scroll = this.experience.scroll
    this.time = this.experience.time
    this.sectionIndex = 0
    this.scaleRatio = this.sizes.isMobile ? 0.5 : 1,
    this.position = {
      x: this.sizes.isMobile ? 0.5: 0.6, 
      y: 0.3, 
      z: 3
    }

    // Setup
    this.setGeometry()
    this.setTextures()
    this.setMaterial()
    this.setMesh()
  }

  setGeometry() 
  {
    this.geometry = new THREE.SphereGeometry(0.15,32,32)
  }

  setTextures() 
  {
    this.texture = {}
    this.texture.color = this.resource.items.spaceColorTexture

  }

  setMaterial() 
  {
    this.material = new THREE.MeshStandardMaterial({ 
      color: '#fff',
      transparent: true,
      map: this.texture.color,
      opacity: 1,
      metalness: 0,
      roughness: 0,
    })
  }

  setMesh()
  {
    this.mesh = new THREE.Mesh(
      this.geometry,
      this.material
    )
    this.mesh.name = 'sphere'
    this.mesh.position.set(this.position.x, this.position.y, this.position.z) 
    this.mesh.scale.set(1 * this.scaleRatio, 1 * this.scaleRatio, 1 * this.scaleRatio)
    this.scene.add(this.mesh)
  }

  update() 
  {
    this.mesh.rotation.x =  this.time.elapsed * 0.4 
    this.mesh.rotation.y =  this.time.elapsed * 0.4 
    if(this.run && (this.scroll.currentSection === 0||this.scroll.currentSection === 1 )){
      this.mesh.position.y = - Math.cos(this.time.elapsed * 0.8 ) * 0.4 - 0.5
      this.mesh.position.x = - Math.sin(this.time.elapsed * 0.8 ) * 0.4
      this.mesh.position.z = - Math.sin(this.time.elapsed * 0.8 ) * 0.4 + 4
    }
  
  }

  scrolling() 
  {
    const isScrollingDown = this.scroll.scrollDirection === "down"
    const isSectionFirstIndex = this.scroll.currentSection === 0
    const scrollY = this.scroll.scrollY

    if( !isScrollingDown && isSectionFirstIndex && scrollY < 20) 
    {
      this.run = false
      this.mesh.position.set(this.position.x, this.position.y, this.position.z) 
      this.mesh.material.opacity = 1
      return 
    }

    if( isScrollingDown && isSectionFirstIndex && scrollY > 20)  
    { 
      this.run = true
      return
    }
  }

}