import Experience from "../Experience"

export default class Cursor
{
  constructor()
  {
    this.experience = new Experience()
    this.sizes = this.experience.sizes
    this.x = 0, 
    this.y = 0 

    //SetUp eventListener
    window.addEventListener('mousemove', (_event) => {
      this.x = (_event.clientX / this.sizes.width - 0.5) * 2
      this.y = - (_event.clientY / this.sizes.height - 0.5) * 2
    })


  }

  destroy()
  {
    window.removeEventListener('mousemove')
  }
  
} 