import * as THREE from 'three'
import Experience from "../../Experience"

export default class Planet
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resource = this.experience.resources
    this.sizes = this.experience.sizes
    this.scroll = this.experience.scroll
    this.time = this.experience.time
    this.sectionIndex = 0
    this.scaleRatio = this.sizes.isMobile ? 0.5 : 1,
    this.widthOffset = this.sizes.isMobile ? 0 : 2
    this.position = {
      x: this.widthOffset + 1, 
      y: 0.2, 
      z: -6
    }

    // Setup
    this.setGeometry()
    this.setTextures()
    this.setMaterial()
    this.setMesh()
  }

  setGeometry() 
  {
    this.geometry = new THREE.SphereGeometry(5,32,32)
  }

  setTextures() 
  {
    this.texture = {}
    this.texture.color = this.resource.items.planetColorTexture
    this.texture.color.colorSpace = THREE.SRGBColorSpace
  }

  setMaterial() 
  {
    this.material = new THREE.MeshStandardMaterial(
      {
        color: '#fff',
        map: this.texture.color,
        metalness: 0.5  ,
        roughness: 0.5,
      }
    )
  }

  setMesh()
  {
    this.mesh = new THREE.Mesh(
      this.geometry,
      this.material
    )
    this.mesh.name = 'planet'
    this.mesh.rotation.z = - Math.PI * 0.01
    this.mesh.position.set(this.position.x, this.position.y, this.position.z) 
    this.mesh.scale.set(1 * this.scaleRatio, 1 * this.scaleRatio, 1 * this.scaleRatio)
    this.scene.add(this.mesh)
  }

  update() 
  {
    this.mesh.rotation.y =  this.time.elapsed * 0.04 
  }

  scrolling() 
  {
    const isScrollingDown = this.scroll.scrollDirection === "down"
    const isSectionFirstIndex = this.scroll.currentSection === 0
    const scrollY = this.scroll.scrollY

    if( !isScrollingDown && isSectionFirstIndex && scrollY < 20) 
    {
      this.mesh.visible  = true
      return 
    }

    if( isScrollingDown && isSectionFirstIndex && scrollY > 20)  
    { 
      this.mesh.visible = false
      return
    }
  }

}