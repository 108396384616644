window.userLanguage = 'en'
const body = document.querySelector("body")
const header = document.querySelector("header")
const languageButtonEl = document.querySelector("button#language")
const allMainSections = document.querySelectorAll('main')
const buttonFlagsEl = document.querySelector('header .btn-language').children
const contactMeLinkEl = document.querySelector('#contact-me-link')
const infinityDevEl = document.querySelector('#infinity-dev')



window.addEventListener('load', (_e)=> {
  const defaultLanguage =  "en"
  const availableLanguage = [
    "en", 
    "en-EN", 
    'en-AM', 
    "fr", 
    "fr-CA", 
    "fr-FR",
    "zh", 
    "zh-CN", 
    "zh-TW"
  ]

  // Detect browser language
  const navigatorLanguage = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language || defaultLanguage;
  console.log("The browser language is:", navigatorLanguage);

  if(availableLanguage.includes(navigatorLanguage))
  {
    switch(navigatorLanguage) 
    {
      case 'fr':
      case'fr-FR': 
      case 'fr-CA' : 
        window.userLanguage = 'fr'
        break

      case 'en': 
      case 'en-AM':
      case 'en-EN': 
        window.userLanguage = 'en'
        break

      case'zh':
      case'zh-CN':
      case'zh-TW': 
        window.userLanguage = 'zh'
        break

      default:
        break
    }
  }

  switchLanguage()

  console.log("The website language will be:", window.userLanguage)


})


if(languageButtonEl) 
{
  languageButtonEl.addEventListener('click', (_e) =>
  {
    const languages = ['en','fr', 'zh']

    const currentLanguageIndex = languages.indexOf(window.userLanguage)

    if(currentLanguageIndex === languages.length - 1)
    {
      window.userLanguage = languages[0]
    } else  
    {
      window.userLanguage = languages[currentLanguageIndex + 1]
    }

    switchLanguage()
  
    console.log("The website language switched on:", window.userLanguage)

  })

}



const switchLanguage = () => {

  // Update contact me link
  contactMeLinkEl.href = `#contact-${window.userLanguage}`
  infinityDevEl.href = `#${window.userLanguage}`

  switch(window.userLanguage) 
  {
    case 'fr':
      contactMeLinkEl.innerHTML = 'Contacter'
      break
    case 'en': 
    contactMeLinkEl.innerHTML = 'Contact'
      break
    case'zh':
      contactMeLinkEl.innerHTML = '联系'
      break

    default:
      break
  }

  // Update class list with show on language button
  for (const flag of buttonFlagsEl) {
    flag.classList.remove("show")
    if (window.userLanguage === flag.classList.value) {
      flag.classList.add("show")
    }
  }

  // Update class list with show on main section
  for (const main of allMainSections) {
    main.classList.remove("show")

    if (window.userLanguage === main.id) {
      header.after(main) 
      main.classList.add("show")
    }else {
      main.classList.remove("show")
      main.remove()
    }
  }
}

