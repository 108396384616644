import * as THREE from 'three'
import Experience from "../../Experience"

export default class Cyril 
{
  constructor() 
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resource = this.experience.resources
    this.sizes = this.experience.sizes
    this.scroll = this.experience.scroll
    this.sectionIndex = 0
    this.position = {
      x: 0, 
      y: this.scroll.objectDistance * this.sectionIndex , 
      z: this.sizes.isMobile ? 1.5: 2
    }

    // Setup
    this.setGeometry()
    this.setTextures()
    this.setMaterial()
    this.setMesh()
  }

  setGeometry()
  {
    this.geometry = new THREE.PlaneGeometry(2, 2.5)
  }

  setTextures()
  {
    this.texture = {}
    this.texture.color = this.resource.items.cyrilColorTexture
    this.texture.color.colorSpace = THREE.SRGBColorSpace
  }

  setMaterial()
  {
   this.material = new THREE.MeshStandardMaterial({
        map: this.texture.color,
        transparent: true,
      }
    )

  }

  setMesh()
  {
    this.mesh = new THREE.Mesh(
      this.geometry,
      this.material
    )
    this.mesh.name = 'cyril'
    this.mesh.position.set(this.position.x, this.position.y, this.position.z) 
    this.scene.add(this.mesh)
  }
  
 
  scrolling() 
  {
    const isScrollingDown = this.scroll.scrollDirection === "down"
    const scrollY = this.scroll.scrollY

    if( !isScrollingDown && scrollY === 0) 
    {
      this.mesh.material.opacity = 1
      return 
    }

    if( !isScrollingDown && scrollY < 200) 
    {
      if(this.mesh.material.opacity < 1) {
        this.mesh.material.opacity  += 0.1
      } 
      return 
    }

    if( isScrollingDown && scrollY < 200)  
    { 

      if(this.mesh.material.opacity > 0) {
        this.mesh.material.opacity  -= 0.1 
      }
   
      return
    }

    if( isScrollingDown  && scrollY > 200)  
    { 
      this.mesh.material.opacity = 0
      return
    }

  }

}


