import EventEmitter from "./EventEmitter";
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

export default class Resources  extends EventEmitter
{
  constructor(sources)
  {
    super()
    this.sources = sources
    this.items = {}
    this.toLoad = this.sources.length
    this.loaded = 0

    // Setup
    this.setLoaders()

    // Load Sources
    this.startLoading() 
  }

  setLoaders() 
  {
   this.loaders = {}
   this.loaders.textureLoader = new THREE.TextureLoader()
   this.loaders.cubeTextureLoader =  new THREE.CubeTextureLoader()
   this.loaders.rgbeLoader = new RGBELoader()
   this.loaders.gltfLoader = new GLTFLoader() 
  }

  startLoading() 
  {

    // Load each sources
    for(const source of this.sources){
      switch(source.type) {
        case 'texture':
          this.loaders.textureLoader.load(source.path,  (file) =>{
            this.sourceLoaded(source, file)
          })
          break
        case 'gltf':
          this.loaders.gltfLoader.load(source.path,  (file) =>{
            this.sourceLoaded(source, file)
          })
          break
        case 'cubeTexture':
          this.loaders.cubeTextureLoader.load(source.path,  (file) =>{
            this.sourceLoaded(source, file)
          })
          break
        case 'hdrTexture':
          this.loaders.rgbeLoader.load(source.path,  (file) =>{
            file.mapping = THREE.EquirectangularReflectionMapping
            this.sourceLoaded(source, file)
          })
          break
        default:
          break
      }
    }
  }

  sourceLoaded(source, file)
  {
      this.items[source.name] = file

      this.loaded++

      if(this.loaded === this.toLoad)
      {
          this.trigger('ready')
      }
  }

}