import * as THREE from 'three'
import Experience from "../../Experience"

export default class Triangle
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resource = this.experience.resources
    this.sizes = this.experience.sizes
    this.scroll = this.experience.scroll
    this.time = this.experience.time
    this.sectionIndex = 2
    this.scaleRatio = this.sizes.isMobile ? 0.5 : 1,
    this.widthOffset = this.sizes.isMobile ? 0 : 2
    this.position = {
      x: - this.widthOffset, 
      y: - this.scroll.objectDistance * this.sectionIndex,
      z: 0
    }

    // Setup
    this.setGeometry()
    this.setTextures()
    this.setMaterial()
    this.setMesh()

    // // Triangle
// const triangle = new THREE.Mesh(
//     new THREE.ConeGeometry(2, 2, 3),
//     new THREE.MeshStandardMaterial({ 
//         color: '#f80', 
//         metalness: 0.5,
//         roughness: 0.5,
//         aoMap: aoBricksTexture,
//         aoMapIntensity: 0.9,
//         transparent: true,
//         opacity: 1,
//         side: THREE.DoubleSide
//     })
// )
// triangle.position.x = parameters.objectSectionWidthOffset
// triangle.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)
  }

  setGeometry()
  {
    this.geometry = new THREE.ConeGeometry(2, 2, 3)
  }

  setTextures()
  {
    this.textures = {}
  }

  setMaterial()
  {
    this.material = new THREE.MeshStandardMaterial({ 
      color: '#f80', 
      metalness: 0.5,
      roughness: 0.5,
      transparent: true,
      opacity: 1
    })
  }

  setMesh()
  {
    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.name = 'triangle'
    this.mesh.position.set(this.position.x, this.position.y, this.position.z) 
    this.mesh.scale.set(1 * this.scaleRatio, 1 * this.scaleRatio, 1 * this.scaleRatio)
    this.scene.add(this.mesh)
  }

  update() 
  {
    this.mesh.rotation.x =  this.time.elapsed * 0.4 
    this.mesh.rotation.y =  this.time.elapsed * 0.4 
  }
}