import './style.css'
import Experience from "./Experience/Experience.js";


new Experience(document.querySelector('canvas.webgl'))



// import * as THREE from 'three'
// import GUI from 'lil-gui'
// import gsap from 'gsap'


// /**
//  * Screen Size
//  */
// const sizes = {
//     width: window.innerWidth,
//     height: window.innerHeight
// }

// /** Scroll */
// let scrollY = window.scrollY
// let currentSection = 0

// /**
//  * Cursor
//  */
// const cursor = {}
// cursor.x = 0
// cursor.y = 0

// /**
//  * Debug
//  */
// const gui = new GUI()

// const parameters = {
//     materialColor: '#fff',
//     objectDistance: 4,
//     objectSectionWidthOffset: sizes.width > 768 ? 2 : 0,
//     particlesCount : 4000,
//     scaleRatio : sizes.width > 768 ? 1 : 0.5,
//     sphereOffset : .3,
//     cyrilOffset : .2
// }

// gui.addColor(parameters, 'objectDistance')
// gui.show(false)


// /**
//  * Base
//  */
// // Canvas
// const canvas = document.querySelector('canvas.webgl')

// // Scene
// const scene = new THREE.Scene()

// //Textures
// const textureLoader = new THREE.TextureLoader()

// const spaceTexture = textureLoader.load('/textures/backgrounds/space.jpg')
// spaceTexture.colorSpace = THREE.SRGBColorSpace

// const planetTexture = textureLoader.load('/textures/backgrounds/earth.jpg')
// planetTexture.colorSpace = THREE.SRGBColorSpace

// const waterMatcapTexture = textureLoader.load('/textures/matcaps/9.png')

// const metalMatcapTexture = textureLoader.load('/textures/matcaps/10.png')

// const aoSandTexture = textureLoader.load('/textures/sand/ao-1.png')

// const aoBricksTexture = textureLoader.load('/textures/bricks/ao-1.jpg')

// const normalTexture = textureLoader.load('/textures/sand/normal-1.png')

// const roughnessTexture = textureLoader.load('/textures/sand/roughness-1.png')

// const cyrilTexture = textureLoader.load('/cyril.png')
// cyrilTexture.colorSpace = THREE.SRGBColorSpace


// /**
//  * Objects
//  */

// // Plane

// const planet = new THREE.Mesh(
//     new THREE.SphereGeometry(1.5,32,32),
//     new THREE.MeshStandardMaterial(
//         {
//             color: '#fff',
//             map:planetTexture,
//             metalness: 0.5,
//             roughness: 0.8,
//         }
//     )
// )

// planet.name = 'planet'
// planet.rotation.z = - Math.PI * 0.01
// planet.position.x =  parameters.objectSectionWidthOffset



// const cyril = new THREE.Mesh(
//     new THREE.PlaneGeometry(2,2.5),
//     new THREE.MeshStandardMaterial(
//         {
//             map: cyrilTexture,
//             transparent: true,
//         }
//     )
// )

// cyril.position.z = sizes.width < 768 ? 1.5: 2



// // Sphere
// const sphere = new THREE.Mesh(
//     new THREE.SphereGeometry(0.15, 32, 32 ),
//     new THREE.MeshStandardMaterial({ 
//         color: '#faf',
//         map: waterMatcapTexture,
//         alphaMap:metalMatcapTexture,
//         transparent: true,
//         opacity: 1,
//         metalness:0.5,
//         roughness:0.6,
//         side: THREE.DoubleSide
//     })
// )
// sphere.position.x = sizes.width < 768 ?  0.5 :  0.6
// sphere.position.z =  3
// sphere.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)
// sphere.castShadow = true

// // Cone
// const cone = new THREE.Mesh(
//     new THREE.ConeGeometry(1, 2, 32),
//     new THREE.MeshStandardMaterial({ 
//         color: '#f1f', 
//         map: spaceTexture, 
//         metalness: 0,
//         roughness: 0,
//         transparent: true,
//         opacity: 1,

        
//     })
    
// )
// cone.position.x =  - parameters.objectSectionWidthOffset
// cone.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)

// // Torus
// const torus = new THREE.Mesh(
//     new THREE.TorusGeometry(0.9, 0.5, 16, 32),
//     new THREE.MeshStandardMaterial({ 
//         color: '#0af',
//         metalness: 0.9,
//         roughness: 1,
//         roughnessMap: roughnessTexture,
//         aoMap: aoSandTexture,
//         normalMap: normalTexture,
//         transparent: true,
//         opacity: 1
//     })
// )
// torus.position.x =  - parameters.objectSectionWidthOffset
// torus.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)

// // Triangle
// const triangle = new THREE.Mesh(
//     new THREE.ConeGeometry(2, 2, 3),
//     new THREE.MeshStandardMaterial({ 
//         color: '#f80', 
//         metalness: 0.5,
//         roughness: 0.5,
//         aoMap: aoBricksTexture,
//         aoMapIntensity: 0.9,
//         transparent: true,
//         opacity: 1,
//         side: THREE.DoubleSide
//     })
// )
// triangle.position.x = parameters.objectSectionWidthOffset
// triangle.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)

// // box
// const box = new THREE.Mesh(
//     new THREE.BoxGeometry(2, 2, 2),
//     new THREE.MeshStandardMaterial({ 
//         color: '#f0f', 
//         map: spaceTexture,
//         metalness: 0,
//         roughness: 0.5,
//         transparent: true,
//         opacity: 1,
//         side: THREE.DoubleSide
//     })
// )
// box.position.x =  - parameters.objectSectionWidthOffset
// box.scale.set(1*parameters.scaleRatio, 1*parameters.scaleRatio,1*parameters.scaleRatio)

// // TorusKnot
// const torusKnot = new THREE.Mesh(
//     new THREE.TorusKnotGeometry(1, 0.25, 100, 20),
//     new THREE.MeshMatcapMaterial({ 
//         color: '#fff', 
//         matcap: waterMatcapTexture,
//         transparent: true,
//         opacity: 1
//     })
// )
// torusKnot.position.x =  parameters.objectSectionWidthOffset
// torusKnot.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)




// cyril.position.y = - parameters.objectDistance * 0 - parameters.cyrilOffset
// planet.position.y = - parameters.objectDistance * 0
// sphere.position.y =  - parameters.objectDistance * 0 + parameters.sphereOffset
// torus.position.y =  - parameters.objectDistance * 1
// triangle.position.y =  - parameters.objectDistance * 2
// box.position.y =  - parameters.objectDistance * 3
// torusKnot.position.y =  - parameters.objectDistance * 4
// cone.position.y = - parameters.objectDistance * 5



// const sectionMeshes = [cone,torus,triangle, box, torusKnot, planet]
// scene.add(cone, torus, triangle, box, torusKnot, sphere , cyril, planet)


// // Particles

// const positions = new Float32Array(parameters.particlesCount * 3)

// for(let i = 0; i < parameters.particlesCount; i++)
// {
//     positions[i * 3 + 0] = (Math.random() - 0.5) * 10
//     positions[i * 3 + 1] = parameters.objectDistance * 0.5 - Math.random() * parameters.objectDistance * sectionMeshes.length
//     positions[i * 3 + 2] = (Math.random() - 0.5) * 10
// }

// const particlesGeometry = new THREE.BufferGeometry()
// particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))


// const particlesMaterial = new THREE.PointsMaterial({
//     color: parameters.materialColor,
//     sizeAttenuation: true,
//     size: 0.003
// })

// const particles = new THREE.Points(particlesGeometry, particlesMaterial)
// scene.add(particles)

// /**
//  * Light
//  */
// const ambientLight = new THREE.AmbientLight(0xffffff, 4)
// const directionalLight = new THREE.DirectionalLight(0xffffff, 8)
// directionalLight.castShadow = true
// scene.add(ambientLight,directionalLight)



// window.addEventListener('mousemove', (event) =>
// {
//     cursor.x = event.clientX / sizes.width - 0.5
//     cursor.y = event.clientY / sizes.height - 0.5
// })

// window.addEventListener('scroll', () => {
//     const header = document.querySelector("header");


//     if(cyril.material.opacity > 0) cyril.material.opacity -=  0.07


//     if (scrollY < 70 || scrollY > window.scrollY) {
//         header.style.top = "0";
     
//     } else {
//         header.style.top = "-70px";


//     }

//     scrollY = window.scrollY
//     const newSection = Math.round(scrollY / sizes.height)

//     if(newSection != currentSection  && newSection < sectionMeshes.length  )
//     {
//         currentSection = newSection

//         gsap.to(
//             sectionMeshes[currentSection].rotation,
//             {
//                 duration: 1.5,
//                 ease: 'power2.inOut',
//                 x: '+=6',
//                 y: '+=3',
//                 z: '+=1.5'
//             }
//         )
//     } 

// })


// window.addEventListener('resize', () =>
// {
//     // Update sizes
//     sizes.width = window.innerWidth
//     sizes.height = window.innerHeight

//     // Update camera
//     camera.aspect = sizes.width / sizes.height
//     camera.updateProjectionMatrix()
 


//     // Scale of meshes 
//     for( const mesh of sectionMeshes){
//         mesh.scale.set(1*parameters.scaleRatio,1*parameters.scaleRatio,1*parameters.scaleRatio)
//     }
    


//     // Update renderer
//     renderer.setSize(sizes.width, sizes.height)
//     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// })

// // Group
// const cameraGroup = new THREE.Group()
// scene.add(cameraGroup)

// // Base camera
// const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
// camera.position.z = 6
// cameraGroup.add(camera)

// /**
//  * Renderer
//  */
// const renderer = new THREE.WebGLRenderer({
//     canvas: canvas
// })
// renderer.setSize(sizes.width, sizes.height)
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// renderer.setClearAlpha(0)
// renderer.shadowMap.enabled = true

// /**
//  * Animate
//  */
// const clock = new THREE.Clock()

// const tick = () =>
// {
//     const elapsedTime = clock.getElapsedTime()

//     // Animate Cyril 

//     if(scrollY === 0   && cyril.material.opacity <= 1) {
//         cyril.material.opacity += 0.01
//     }
    
//     // Animate camera
//     camera.position.y = - scrollY / sizes.height * parameters.objectDistance

//     const parallaxX = cursor.x
//     const parallaxY = - cursor.y

//     if(sizes.width > 768){
//         cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 0.1
//         cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 0.1
//     }


//     //Update particles
//     particles.rotation.y = elapsedTime * 0.05


//     //Animate sphere
//     sphere.rotation.x = elapsedTime * 0.1
//     sphere.rotation.z = elapsedTime * 0.2

//     // Rotation of meshes
//     for( const mesh of sectionMeshes){
//         if(mesh.name === 'planet') {
//             mesh.rotation.y = elapsedTime * 0.04
//         } 
//         else {
//             mesh.rotation.x = elapsedTime * 0.1
//             mesh.rotation.z = elapsedTime * 0.2
//         }
      
//     }

//     // Render
//     renderer.render(scene, camera)

//     // Call tick again on the next frame
//     window.requestAnimationFrame(tick)
// }

// tick()