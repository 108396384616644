import env from './../env.prod.json'

// DOM Elements
const htmFormEn = document.querySelector('main#en form');
const htmFormFr = document.querySelector('main#fr form');
const htmFormZh = document.querySelector('main#zh form');

const submitBtnEnEl = document.querySelector('main#en form button');
const submitBtnFrEl = document.querySelector('main#fr form button');
const submitBtnZhEl = document.querySelector('main#zh form button');

const dialogEl =  document.querySelector('body .dialog-box')
const closePopMessageEl = document.querySelector('body .dialog-box .close');

const formEls = [htmFormEn, htmFormFr, htmFormZh]
const submitBtnEls = [submitBtnEnEl, submitBtnFrEl, submitBtnZhEl];


// Global variables

let timeoutId = null;
let sendingContact = false;

// Utils functions

const toggleSubmit = () =>
{
  for(let btnEl of submitBtnEls) 
  {
    if(sendingContact) 
    {
      btnEl.setAttribute('disabled', true)
      btnEl.classList.add('disabled')
    }
    else 
    {
      btnEl.removeAttribute('disabled')
      btnEl.classList.remove('disabled')
    } 
  }

}

const isEmpty = (value) =>
{
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}

const createTimeOut = () => 
{
 if(!timeoutId){
  timeoutId = setTimeout(
    () =>{
      dialogEl.classList.remove('show')
      removeTimeout()
    },
    10000
  )
 }
}

const removeTimeout = () =>
{
  if(timeoutId) {
    clearTimeout(timeoutId)
    timeoutId = null
  }
}

const applyDialog = (message = '', color = 'black') => 
{
  const textEl = document.createTextNode(message)
  dialogEl.appendChild(textEl) 
  dialogEl.style.color = color
  dialogEl.classList.add("show")
  sendingContact = false
  toggleSubmit()
  createTimeOut()
}

const setErrorDialog = () => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = 'Une erreur et survenue, message non envoyé, rechargez la page et essayez à nouveau.'
      break
    case 'en' :
      message = 'An error occurred, message not sent, reload the page and try again.'
      break
    case 'zh' :
      message = '发生错误，消息未发送，请重新加载页面，然后重试。'
      break
  }
  applyDialog(message, 'red')
}

const setSuccessDialog = () => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = 'Votre message a bien été envoyé, je vous répondrai dès que possible.'
      break
    case 'en' :
      message = 'Your message has been sent, I will reply as soon as possible.'
      break
    case 'zh' :
      message = '您的消息已发送，我会尽快回复'
      break
  }
  applyDialog(message, 'green')
}

const setBadEmailDialog = () => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = "Le format de votre mail n'est pas correct, veuillez corriger pour envoyer votre message."
      break
    case 'en' :
      message = 'The format of your email is not correct, please correct it to send your message.'
      break
    case 'zh' :
      message = '您的电子邮件格式不正确，请更正以发送您的消息。'
      break
  }
  applyDialog(message, 'orange')
}

const setEmptyFieldsDialog = ()  => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = 'Tous les champs sont obligatoires pour envoyer votre message.'
      break
    case 'en' :
      message = 'All fields are required to send your message.'
      break
    case 'zh' :
      message = '发送消息所需的所有字段。'
      break
  }
  applyDialog(message, 'orange')
}

// Event handlers
const handleSubmit =  async (e) => 
{
  e.preventDefault()
  sendingContact = true
  toggleSubmit()


  dialogEl.classList.remove("show")
  dialogEl.lastChild.remove()


  // Select  DOM Form Inputs
  const form = {
    lastnameEl: document.querySelector(`form.${window.userLanguage} #lastname`),
    firstnameEl: document.querySelector(`form.${window.userLanguage} #firstname`),
    mailEl: document.querySelector(`form.${window.userLanguage} #mail`),
    messageEl : document.querySelector(`form.${window.userLanguage} #message`),
  }

  // Test empty field
  if(
    isEmpty(form.firstnameEl.value) ||
    isEmpty(form.lastnameEl.value) ||
    isEmpty(form.mailEl.value) ||
    isEmpty(form.messageEl.value)
    ) 
  {
    setEmptyFieldsDialog()
    return
  }
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  
  // Test mail
  if(!regexEmail.test(mail.value)) 
  {
    setBadEmailDialog()
    return
  }


  fetch(env.API_BASE_PATH + "contact", 
    {
    method: 'POST',
    headers: 
      {
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(
      {
        lastname: form.lastnameEl.value,
        firstname: form.firstnameEl.value ,
        mail: form.mailEl.value,
        message: form. messageEl.value,
        langue: window.userLanguage
      })
    }
  )
  .then((response) => 
    { 
      if(response.status === 201){
          setSuccessDialog()
          form.lastnameEl.value = ''
          form.firstnameEl.value = ''
          form.mailEl.value = ''
          form .messageEl.value = ''
      }else {
        setErrorDialog()
      }
    }
  )
  .catch((error) => 
    {
      console.log(error)
      setErrorDialog()
    }
  )     
}

const handleCloseClick = (_e) => 
{
  dialogEl.classList.remove('show')
  if(timeoutId) removeTimeout()
} 

//Event Listeners

for(let formEl of formEls) 
{
  formEl.addEventListener('submit', handleSubmit)
}

closePopMessageEl.addEventListener('click', handleCloseClick)
